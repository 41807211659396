import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'arch-dataset-empty',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './dataset-empty.component.html',
  styleUrls: ['./dataset-empty.component.scss'],
})
export class DatasetEmptyComponent {
  @Input() icon!: any;
  @Input() label!: string;
}
