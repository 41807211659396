import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  query,
  where,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';
import { Auth, user } from '@angular/fire/auth';
import { ArchOrgRef } from './org.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

const COLLECTION_NAME = 'org_role';

@Injectable({
  providedIn: 'root',
})
export class OrgRoleService {
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private functions: Functions
  ) {}

  collectionRef() {
    return collection(
      this.firestore,
      COLLECTION_NAME
    ) as CollectionReference<ArchOrgRole>;
  }

  userOrgRole$(orgId: string | null): Observable<RoleType> {
    if (!orgId) return of('none');
    return user(this.auth).pipe(
      switchMap((user) => {
        if (user) {
          const ref = doc(
            this.collectionRef(),
            `${user.uid}_${orgId}`
          ) as DocumentReference<ArchOrgRole>;
          return docData(ref);
        }
        return of(null);
      }),
      map((role) => {
        return role ? role.role : 'none';
      })
    );
  }

  userOrgs$(): Observable<ArchOrgRole[]> {
    return user(this.auth).pipe(
      switchMap((user) => {
        if (user) {
          const ref = this.collectionRef();
          const q = query(ref, where('uid', '==', user.uid));
          return collectionData(q, { idField: 'id' });
        }
        return [];
      })
    );
  }

  orgUsers$(orgId: string): Observable<ArchOrgRole[]> {
    const ref = this.collectionRef();
    const q = query(ref, where('orgId', '==', orgId));
    return collectionData(q, { idField: 'id' }).pipe(
      map((list) => list.sort((a, b) => a.email.localeCompare(b.email)))
    );
  }

  inviteUsersToOrgWithEmails(orgId: string, emails: string) {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const list = emails.match(regex) ?? [];
    return Promise.allSettled(
      list.map((email) => this.inviteUserToOrg(orgId, email))
    );
  }

  inviteUserToOrg(orgId: string, email: string) {
    return httpsCallable(
      this.functions,
      'inviteUserToOrgWithEmails'
    )({
      orgId,
      email,
    });
  }
}

export type RoleType = 'owner' | 'admin' | 'editor' | 'none';

export interface ArchOrgRole {
  id: string;
  orgId: string;
  role: RoleType;
  uid: string;
  org: ArchOrgRef;
  email: string;
  status: 'active' | 'invited' | 'inactive';
}
