import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe } from '@angular/common';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage,
} from '@angular/fire/storage';
import { DefaultValueAccessor, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { LOADING_BAR_CONFIG, LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import * as Sentry from '@sentry/angular';
import { NgForTrackByPropertyModule } from 'ng-for-track-by-property';
import { FILE_UPLOAD_SERVICE_TOKEN } from 'projects/arch-lib/src/lib/file-upload-service';
import { FkBreadcrumbsHooksDirective } from 'projects/arch-lib/src/lib/fk-breadcrumbs/fk-breadcrumb.directive';
import { environment } from '../environments/environment';
import { TestProgressPipe } from './@pipes/test-progress.pipe';
import { TestScorePipe } from './@pipes/test-score.pipe';
import { TestTimePipe } from './@pipes/test-time.pipe';
import { UploadService } from './@services/upload.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    FkBreadcrumbsHooksDirective,
    BrowserModule,
    AppRoutingModule,
    NgForTrackByPropertyModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: false,
      },
      validators: [
        { name: 'requiredTrue', validation: Validators.requiredTrue },
      ],
    }),
    FormlyBootstrapModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      // enableMultiTabIndexedDbPersistence(firestore).then(
      //   () => resolvePersistenceEnabled(true),
      //   () => resolvePersistenceEnabled(false)
      // );
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    FontAwesomeModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    { provide: FILE_UPLOAD_SERVICE_TOKEN, useClass: UploadService },
    TestProgressPipe,
    TestScorePipe,
    TestTimePipe,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

DefaultValueAccessor.prototype.registerOnChange = function (
  fn: (_: string | null) => void,
): void {
  this.onChange = (value: string | null) => {
    fn(value === '' ? null : value);
  };
};
