import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, delay, map, startWith } from 'rxjs/operators';
import { Dataset } from './dataset.component';

export function datasetMap<T>(
  delayTime: number = 0
): OperatorFunction<T[], Dataset<T>> {
  return (source: Observable<T[]>) =>
    source.pipe(
      delayTime > 0 ? delay(delayTime) : (obs) => obs,
      map(
        (data: T[]) =>
          ({
            status: 'loaded',
            data: data && data.length ? data : null,
          } as Dataset<T>)
      ),
      startWith({ status: 'loading' } as Dataset<T>),
      catchError((error) => {
        return of({ status: 'error', error } as Dataset<T>);
      })
    );
}
