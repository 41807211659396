import { InjectionToken } from '@angular/core';
import { MetaData } from 'projects/arch-bowl/src/app/@services/meta.service';

export interface FileUploadServiceInterface {
  upload(file: File | Blob, fileName?: string): Promise<FileUploadServiceFile>;
  search(query: string): Promise<FileUploadServiceFile[]>;
}

export const FILE_UPLOAD_SERVICE_TOKEN =
  new InjectionToken<FileUploadServiceInterface>('FILE_UPLOAD_SERVICE_TOKEN');

export interface FileUploadServiceFile {
  id: string;
  orgId: string;
  name: string;
  type: string;
  url: string;
  meta: MetaData;
}

export const base64ToBinary = (base64: string) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const hexToBinary = (hex: string) => {
  const buffer = new ArrayBuffer(hex.length / 2);
  const view = new DataView(buffer);
  for (let i = 0; i < hex.length; i += 2) {
    view.setUint8(i / 2, parseInt(hex.substr(i, 2), 16));
  }
  return buffer;
};

export const hexToBase64 = (hexString: string): string => {
  return btoa(
    hexString
      .match(/\w{2}/g)!
      .map((char) => {
        return String.fromCharCode(parseInt(char, 16));
      })
      .join('')
  );
};
