export const environment = {
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyAV8Pn9hjPIS551aoHWs_sJM4dg_qQevFk',
    authDomain: 'arch-bowl.alca.is',
    projectId: 'arch-bowl',
    storageBucket: 'arch-bowl.appspot.com',
    messagingSenderId: '843445634428',
    appId: '1:843445634428:web:f8998a606d06defa44d0f9',
    measurementId: 'G-R2VRQM94HB',
  },
};
