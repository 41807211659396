import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'arch-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './arch-error.component.html',
  styleUrls: ['./arch-error.component.scss'],
})
export class ArchErrorComponent {
  @Input() error!: string;
}
