import { Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private auth: Auth) {}

  async metaize(model: any): Promise<MetaData> {
    const now = Date.now();
    const usr = await firstValueFrom(user(this.auth));
    return {
      createdAt: model.meta?.createdAt ?? now,
      createdBy:
        model.meta?.createdBy ?? usr?.displayName ?? usr?.email ?? null,
      createdUid: model.meta?.createdUid ?? usr?.uid ?? null,
      updatedAt: now,
      updateBy: usr?.displayName ?? usr?.email ?? null,
      updatedUid: usr?.uid ?? null,
    };
  }
}

export interface MetaData {
  createdAt: number;
  createdBy: string | null;
  createdUid: string | null;
  updatedAt: number;
  updateBy?: string | null;
  updatedBy?: string | null;
  updatedUid: string | null;
}
