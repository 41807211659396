import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  hasCustomClaim,
  canActivate,
  customClaims,
  AuthPipe,
  loggedIn,
  idTokenResult,
} from '@angular/fire/auth-guard';
import { ToolsComponent } from './tools/tools.component';
import { combineLatest, map, pipe } from 'rxjs';

const isEntryUser = () => hasCustomClaim('entryId');

const redirectEntryUser: (redirect: string | any[]) => AuthPipe = (redirect) =>
  pipe(
    hasCustomClaim('entryId'),
    map((isEntryUser) => isEntryUser || '/take-test'),
  );

const redirectAsAppropriate = () =>
  pipe(
    idTokenResult,
    map((idTokenResult) => {
      if (!idTokenResult) {
        return '/signin';
      }
      if (idTokenResult.claims['entryId']) {
        return '/take-test';
      }
      return true;
    }),
  );

const redirectedSignedOutToSignin = () => {
  return redirectUnauthorizedTo(['/signin']);
};

const redirectSignedInToTools = () => {
  return redirectLoggedInTo(['/t']);
};

const routes: Routes = [
  {
    path: 'signin',
    loadChildren: () =>
      import('./auth/signin/signin.module').then((m) => m.SigninModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectSignedInToTools },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectSignedInToTools },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent,
      ),
  },
  // {
  //   path: '',
  //   component: PageComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadComponent: () =>
  //         import('./pages/home/home.component').then((m) => m.HomeComponent),
  //     },
  //     {
  //       path: 'features',
  //       loadComponent: () =>
  //         import('./pages/features/features.component').then(
  //           (m) => m.FeaturesComponent
  //         ),
  //     },
  //     {
  //       path: 'pricing',
  //       loadComponent: () =>
  //         import('./pages/pricing/pricing.component').then(
  //           (m) => m.PricingComponent
  //         ),
  //     },
  //     {
  //       path: 'contact',
  //       loadComponent: () =>
  //         import('./pages/contact/contact.component').then(
  //           (m) => m.ContactComponent
  //         ),
  //     },
  //   ],
  // },
  {
    path: 'take-test',
    loadComponent: () =>
      import('./take-test/take-test.component').then(
        (m) => m.TakeTestComponent,
      ),
  },
  {
    path: 't',
    component: ToolsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectAsAppropriate },
    children: [
      {
        path: '',
        redirectTo: 'competitions',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./tools/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent,
          ),
      },
      {
        path: 'competitions',
        loadComponent: () =>
          import('./tools/competitions/competitions.component').then(
            (m) => m.CompetitionsComponent,
          ),
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './tools/competitions/competition-list/competition-list.component'
              ).then((m) => m.CompetitionListComponent),
          },
          {
            path: ':competitionId/tests/:testId',
            loadComponent: () =>
              import(
                './tools/competitions/competition-test/competition-test.component'
              ).then((m) => m.CompetitionTestComponent),
            children: [
              {
                path: 'details',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-test/competition-test-details/competition-test-details.component'
                  ).then((m) => m.CompetitionTestDetailsComponent),
              },
              {
                path: 'questions',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-test/competition-test-questions/competition-test-questions.component'
                  ).then((m) => m.CompetitionTestQuestionsComponent),
              },
              {
                path: 'participants',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-test/competition-test-participants/competition-test-participants.component'
                  ).then((m) => m.CompetitionTestParticipantsComponent),
              },
              {
                path: '',
                redirectTo: 'details',
                pathMatch: 'full',
              },
            ],
          },
          {
            path: ':competitionId',
            loadComponent: () =>
              import(
                './tools/competitions/competition-detail/competition-detail.component'
              ).then((m) => m.CompetitionDetailComponent),
            children: [
              {
                path: 'about',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-detail/competition-about/competition-about.component'
                  ).then((m) => m.CompetitionAboutComponent),
              },
              {
                path: 'rules',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-detail/competition-rules/competition-rules.component'
                  ).then((m) => m.CompetitionRulesComponent),
              },
              {
                path: 'roster',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-detail/competition-roster/competition-roster.component'
                  ).then((m) => m.CompetitionRosterComponent),
              },
              {
                path: 'tests',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-detail/competition-tests/competition-tests.component'
                  ).then((m) => m.CompetitionTestsComponent),
              },
              {
                path: 'teams',
                loadComponent: () =>
                  import(
                    './tools/competitions/competition-detail/competition-teams/competition-teams.component'
                  ).then((m) => m.CompetitionTeamsComponent),
              },
              {
                path: '',
                redirectTo: 'about',
                pathMatch: 'full',
              },
            ],
          },
        ],
      },
      {
        path: 'testbanks',
        loadComponent: () =>
          import('./tools/tests/tests.component').then((m) => m.TestsComponent),
      },
      {
        path: 'testbanks/:testBankId',
        loadComponent: () =>
          import('./tools/tests/test-detail/test-detail.component').then(
            (m) => m.TestDetailComponent,
          ),
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./tools/users/users.component').then((m) => m.UsersComponent),
      },
      {
        path: 'tests',
        redirectTo: 'testbanks',
        pathMatch: 'prefix',
      },
    ],
  },
  {
    path: 'help',
    loadComponent: () =>
      import('./help/help.component').then((m) => m.HelpComponent),
    children: [
      {
        path: 'guides/getting-started',
        loadComponent: () =>
          import(
            './help/guides/getting-started/getting-started.component'
          ).then((m) => m.GettingStartedComponent),
      },
      {
        path: 'guides/markdown',
        loadComponent: () =>
          import('./help/guides/markdown/markdown.component').then(
            (m) => m.MarkdownComponent,
          ),
      },
      {
        path: 'guides/latex',
        loadComponent: () =>
          import('./help/guides/latex/latex.component').then(
            (m) => m.LatexComponent,
          ),
      },
      {
        path: '',
        redirectTo: 'guides/getting-started',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 't',
    pathMatch: 'full',
  },
  {
    outlet: 'modal',
    path: '',
    children: [
      {
        path: 'e/change-password',
        loadComponent: () =>
          import(
            '../../../arch-lib/src/lib/auth/change-password/change-password.component'
          ).then((c) => c.ChangePasswordComponent),
      },
      {
        path: 'e/testbank-folder',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-folder-dialog/edit-folder-dialog.component'
          ).then((c) => c.EditFolderDialogComponent),
      },
      {
        path: 'e/testbank-folder/:id',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-folder-dialog/edit-folder-dialog.component'
          ).then((c) => c.EditFolderDialogComponent),
      },
      {
        path: 'e/testbank',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-testbank-dialog/edit-testbank-dialog.component'
          ).then((c) => c.EditTestbankDialogComponent),
      },
      {
        path: 'e/testbank/:id',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-testbank-dialog/edit-testbank-dialog.component'
          ).then((c) => c.EditTestbankDialogComponent),
      },
      {
        path: 'e/testbank/:testBankId/question',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-question-dialog/edit-question-dialog.component'
          ).then((c) => c.EditQuestionDialogComponent),
      },
      {
        path: 'e/testbank/:testBankId/question/:questionId',
        loadComponent: () =>
          import(
            './tools/tests/@dialogs/edit-question-dialog/edit-question-dialog.component'
          ).then((c) => c.EditQuestionDialogComponent),
      },
      {
        path: 'e/competition/:competitionId/test/:testId/question/:questionId',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-competition-question/edit-competition-question.component'
          ).then((c) => c.EditCompetitionQuestionComponent),
      },
      {
        path: 'e/competition/:competitionId/test/:testId/report',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/test-report/test-report.component'
          ).then((c) => c.TestReportComponent),
      },
      {
        path: 'e/competition',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-competition-dialog/edit-competition-dialog.component'
          ).then((c) => c.EditCompetitionDialogComponent),
      },
      {
        path: 'e/competition/:id',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-competition-dialog/edit-competition-dialog.component'
          ).then((c) => c.EditCompetitionDialogComponent),
      },
      {
        path: 'e/competition/:competitionId/test',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-test-dialog/edit-test-dialog.component'
          ).then((c) => c.EditTestDialogComponent),
      },
      {
        path: 'e/competition/:competitionId/test/:testId/question',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/add-questions/add-questions.component'
          ).then((c) => c.AddQuestionsComponent),
      },
      {
        path: 'e/competition/:competitionId/test/:testId',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-test-dialog/edit-test-dialog.component'
          ).then((c) => c.EditTestDialogComponent),
      },
      {
        path: 'e/competition/:competitionId/test/:testId/add-participant',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/add-participant-dialog/add-participant-dialog.component'
          ).then((c) => c.AddParticipantDialogComponent),
      },
      {
        path: 'e/competition/:competitionId/participant/:participantId',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-participant-dialog/edit-participant-dialog.component'
          ).then((c) => c.EditParticipantDialogComponent),
      },
      {
        path: 'e/competition/:id/register',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/register-competition-dialog/register-competition-dialog.component'
          ).then((c) => c.RegisterCompetitionDialogComponent),
      },
      {
        path: 'e/competition/:id/roster',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/edit-roster-dialog/edit-roster-dialog.component'
          ).then((c) => c.EditRosterDialogComponent),
      },
      {
        path: 'f/competition',
        loadComponent: () =>
          import(
            './tools/competitions/@dialogs/find-competition-dialog/find-competition-dialog.component'
          ).then((c) => c.FindCompetitionDialogComponent),
      },
      {
        path: 'e/org',
        loadComponent: () =>
          import(
            './tools/orgs/@dialogs/edit-org-dialog/edit-org-dialog.component'
          ).then((c) => c.EditOrgDialogComponent),
      },
      {
        path: 'e/org/:id',
        loadComponent: () =>
          import(
            './tools/orgs/@dialogs/edit-org-dialog/edit-org-dialog.component'
          ).then((c) => c.EditOrgDialogComponent),
      },
      {
        path: 'e/user',
        loadComponent: () =>
          import(
            './tools/users/@dialogs/edit-user-dialog/edit-user-dialog.component'
          ).then((c) => c.EditUserDialogComponent),
      },
      {
        path: 'i/user',
        loadComponent: () =>
          import(
            './tools/users/@dialogs/invite-user-dialog/invite-user-dialog.component'
          ).then((c) => c.InviteUserDialogComponent),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
