import { Injectable } from '@angular/core';
import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from '@angular/fire/storage';

import { Auth, user } from '@angular/fire/auth';

import {
  FileUploadServiceFile,
  FileUploadServiceInterface,
} from 'projects/arch-lib/src/lib/file-upload-service';
import { firstValueFrom } from 'rxjs';
import { OrgService } from './org.service';
import {
  Firestore,
  addDoc,
  collection,
  doc,
  setDoc,
  where,
  query,
  orderBy,
  collectionData,
  CollectionReference,
  limit,
} from '@angular/fire/firestore';
import { MetaService } from './meta.service';
import mime from 'mime';

@Injectable({
  providedIn: 'root',
})
export class UploadService implements FileUploadServiceInterface {
  constructor(
    private storage: Storage,
    private auth: Auth,
    private orgService: OrgService,
    private fs: Firestore,
    private meta: MetaService
  ) {}

  async upload(
    file: File | Blob,
    fileName?: string
  ): Promise<FileUploadServiceFile> {
    const orgId = this.orgService.activeOrgId$.value ?? 'no-org';
    const path = `uploads/${orgId}/${fileName}`;
    const storageRef = ref(this.storage, path);
    const u = await firstValueFrom(user(this.auth));
    if (u) {
      const customMetadata: any = {};
      if (u.uid) {
        customMetadata.uid = u.uid;
      }
      if (u.email) {
        customMetadata.email = u.email;
      }
      await uploadBytes(storageRef, file, {
        customMetadata,
      });
      const type = mime.getExtension(fileName ?? file.type ?? '');
      const fileRef = doc(collection(this.fs, 'uploads'));
      const result: FileUploadServiceFile = {
        orgId,
        id: fileRef.id,
        name: fileName ?? file.name ?? 'Untitled',
        type: type ? type : 'application/octet-stream',
        url: await getDownloadURL(storageRef),
        meta: await this.meta.metaize({}),
      };
      await setDoc(fileRef, result);
      return result;
    }
    throw new Error('No user');
  }

  async search(term: string): Promise<FileUploadServiceFile[]> {
    const orgId = this.orgService.activeOrgId$.value ?? 'no-org';
    const collectionRef = collection(
      this.fs,
      'uploads'
    ) as CollectionReference<FileUploadServiceFile>;
    const q = query(
      collectionRef,
      where('orgId', '==', orgId),
      orderBy('meta.createdAt', 'desc'),
      limit(100)
    );
    return firstValueFrom(collectionData(q, { idField: 'id' }));
  }
}
