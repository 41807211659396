import { Pipe, PipeTransform } from '@angular/core';
import { ArchBowlEntry } from '../@services/competition.service';

@Pipe({
  name: 'testProgress',
  standalone: true,
})
export class TestProgressPipe implements PipeTransform {
  transform(entry: ArchBowlEntry): string | null {
    if (!entry || !entry.state) return null;
    const total = entry.state.questions.length;
    const answered = Object.values(entry.state.answers).filter(
      (a) => !!a,
    ).length;
    return `${answered} / ${total}`;
  }
}
