<div>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed">
        <fa-icon [fixedWidth]="true" [icon]="isNavbarCollapsed ? menuBarsIcon : menuExitIcon"></fa-icon>
        <span class="d-block" style="font-size:xx-small">Menu</span>
      </button>
      <a class="navbar-brand" routerLink="/t/competitions">
        <img class="logo" src="assets/archbowl-logo-white.svg" alt="Arch Bowl Logo" style="height:35px;max-width:100%">
      </a>
      <button class="navbar-toggler" type="button" (click)="signout()" title="Sign out">
        <fa-icon [fixedWidth]="true" [icon]="exitIcon"></fa-icon>
        <span class="d-block" style="font-size:xx-small">Logout</span>
      </button>
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse">
        <ng-container *ngIf="orgId$ | async as orgId;">
          <ul class="navbar-nav">
            <!-- <li class="nav-item">
              <a class="nav-link" [routerLink]="['/t', 'dashboard']" routerLinkActive="active">
                Dashboard
              </a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/t', 'competitions']" routerLinkActive="active">
                Competitions
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/t', 'testbanks']" routerLinkActive="active">
                Test Banks
              </a>
            </li>
            <ng-container *ngIf="orgRole$ | async as role">
              <li class="nav-item" *ngIf="['owner', 'admin'].includes(role)">
                <a class="nav-link" [routerLink]="['/t', 'users']" routerLinkActive="active">
                  Users
                </a>
              </li>
            </ng-container>
          </ul>
          <ul class="navbar-nav ms-auto" (click)="isNavbarCollapsed = true">
            <li class="nav-item d-none d-lg-inline-block">
              <a class="nav-link" [routerLink]="['/', 'help']">
                <fa-icon [icon]="helpIcon"></fa-icon>
              </a>
            </li>
            <ng-container *ngIf="orgRole$ | async as role">
              <!-- <li class="nav-item d-none d-lg-inline-block" *ngIf="['owner', 'admin'].includes(role)">
                <a class="nav-link" [routerLink]="['/', {outlets:{modal:['e', 'org', orgId]}}]">
                  <fa-icon [icon]="settingsIcon"></fa-icon>
                  <span *ngIf="org$ | async as org;" class="ms-1">{{ org.name }}</span>
                </a>
              </li> -->
              <ng-container *ngIf="['owner', 'admin'].includes(role)">
                <ng-container *ngIf="org$ | async as org">
                  <li class="nav-item d-none d-lg-inline-block" ngbDropdown>
                    <a class="nav-link" tabindex="0" ngbDropdownToggle id="userDropdownMenu" role="button">
                      <span class="ms-1">{{ org.name }}</span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="userDropdownMenu" class="dropdown-menu dropdown-menu-end">
                      <a ngbDropdownItem [routerLink]="['/', {outlets:{modal:['e', 'org', org.id]}}]">Edit Settings</a>

                      <hr class="dropdown-divider">
                      <a ngbDropdownItem [routerLink]="[]" (click)="selectOrg(null)">Switch Account</a>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ng-container>
            <li class="nav-item d-none d-lg-inline-block" ngbDropdown *ngIf="user$ | async as user">
              <a class="nav-link" tabindex="0" ngbDropdownToggle id="userDropdownMenu" role="button">
                {{ user?.displayName ?? user?.email }}
              </a>
              <div ngbDropdownMenu aria-labelledby="userDropdownMenu" class="dropdown-menu dropdown-menu-end">
                <a ngbDropdownItem [routerLink]="['/', {outlets:{modal:'e/change-password'}}]">Change Password</a>
                <a ngbDropdownItem [routerLink]="[]" (click)="signout()">Signout</a>
              </div>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </nav>
  <div class="container-fluid">
    <ng-container *ngIf="orgIsSelected$ | async else orgChooser">
      <router-outlet fk-breadcrumbs-hooks></router-outlet>
    </ng-container>
  </div>
</div>

<ng-template #orgChooser>
  <div *ngIf="orgs$ | async as orgs" class="container my-5">
    <arch-dataset [dataset]="orgs">
      <h3 class="mb-3">Your Organizations</h3>
      <p>Choose from your list of organizations below.</p>
      <div class="row">
        <div class="col-sm-6 mb-4" *ngFor="let role of orgs.data">
          <button (click)="selectOrg(role.orgId)" class="w-100 h-100 p-3 btn btn-outline-primary text-center shadow"
            tabindex="0">
            <h5 class="mb-0">{{ role.org.name }}</h5>
          </button>
        </div>
        <!-- <div class="col-sm-6 mb-4" *ngIf="orgs.data.length < 1">
          <button [routerLink]="['/', {outlets:{modal:['e', 'org']}}]"
            class="w-100 h-100 p-3 btn btn-outline-primary text-center shadow" tabindex="0">
            <h5 class="mb-0">Add Organization</h5>
          </button>
        </div> -->
      </div>
      <arch-dataset-empty [icon]="orgsIcon" label="No Organizations Yet">
        <p>Organizations are the place you create groups that host or particpate in Scholastic Competitions. It could be
          your school, club, or league.</p>
        To begin, <a [routerLink]="['/', {outlets:{modal:'e/org'}}]">create</a> your own.
        To learn more, visit the <a routerLink="/help/organizations">help</a> page.
      </arch-dataset-empty>
    </arch-dataset>
  </div>
</ng-template>