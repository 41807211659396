import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  faCircleQuestion,
  faGear,
  faSchool,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {
  NgbCollapseModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { user, Auth, signOut } from '@angular/fire/auth';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Observable,
  combineLatest,
  firstValueFrom,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { OrgService } from '../@services/org.service';
import { ArchOrgRole, OrgRoleService } from '../@services/org-role.service';
import { DatasetComponent } from 'projects/arch-lib/src/lib/ui/dataset/dataset.component';
import { datasetMap } from 'projects/arch-lib/src/lib/ui/dataset/dataset.operator';
import { DatasetEmptyComponent } from 'projects/arch-lib/src/lib/ui/dataset/dataset-empty/dataset-empty.component';
import { FkBreadcrumbsHooksDirective } from 'projects/arch-lib/src/lib/fk-breadcrumbs/fk-breadcrumb.directive';
import { FkBreadcrumbsService } from 'projects/arch-lib/src/lib/fk-breadcrumbs/fk-breadcrumbs.service';

@Component({
  selector: 'app-tools',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgbCollapseModule,
    NgbDropdownModule,
    DatasetComponent,
    DatasetEmptyComponent,
    FkBreadcrumbsHooksDirective,
  ],
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
  isScrolled = false;
  isNavbarCollapsed = true;
  isSmall = false;

  menuBarsIcon = faBars;
  menuExitIcon = faTimes;
  exitIcon = faSignOutAlt;
  settingsIcon = faGear;
  helpIcon = faCircleQuestion;

  orgsIcon = faSchool;

  user$ = user(this.auth);
  orgId$ = this.orgService.activeOrgId$;

  orgRole$ = this.orgId$.pipe(
    switchMap((orgId) => this.orgRoleService.userOrgRole$(orgId)),
  );

  // orgIsSelected$ = this.orgId$.pipe(map((orgId) => !!orgId));
  orgIsSelected$ = this.orgRole$.pipe(map((role) => role !== 'none'));

  orgs$ = this.orgRoleService.userOrgs$().pipe(
    tap((orgs) => {
      if (orgs.length === 1) {
        this.selectOrg(orgs[0].orgId);
      }
    }),
    datasetMap(50),
  );

  org$ = this.orgService.activatedOrg$();

  breadcrumbService = inject(FkBreadcrumbsService);

  constructor(
    private route: ActivatedRoute,
    private auth: Auth,
    private router: Router,
    private orgService: OrgService,
    private orgRoleService: OrgRoleService,
  ) {}

  selectOrg(orgId: string | null) {
    this.orgService.activeOrgId$.next(orgId);
    this.breadcrumbService.clear();
    if (orgId) {
      this.router.navigateByUrl(`/t/competitions`);
    } else {
      this.router.navigateByUrl(`/t`);
    }
  }

  signout() {
    signOut(this.auth).then(() => {
      this.selectOrg(null);
      this.router.navigateByUrl('/signin');
    });
  }
}
