import { Directive, HostListener, Input, inject } from '@angular/core';
import { FkBreadcrumbsService } from './fk-breadcrumbs.service';

@Directive({
  selector: '[fk-breadcrumbs-hooks]',
  standalone: true,
})
export class FkBreadcrumbsHooksDirective {
  breadcrumbService = inject(FkBreadcrumbsService);

  @Input() isBreadcrumbRoot = false;

  @HostListener('activate', ['$event'])
  onActivate(componentRef: any) {
    this.breadcrumbService.activate(componentRef, this.isBreadcrumbRoot);
  }

  @HostListener('deactivate', ['$event'])
  onDeactivate(componentRef: any) {
    this.breadcrumbService.deactivate(componentRef);
  }
}
