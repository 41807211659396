<ng-container [ngSwitch]="dataset.status">
  <arch-loading *ngSwitchCase="'loading'"></arch-loading>
  <arch-error *ngSwitchCase="'error'" [error]="dataset.error"></arch-error>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="dataset.data && dataset.data.length; else (emptyDataset ? emptyCustom : emptyDefault)">
      <ng-content></ng-content>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyDefault>
  <div class="text-center text-black-50 mx-auto" style="max-width:400px;">
    <h3 class="my-2" style="font-size:xx-large;">
      No Data Found
    </h3>
  </div>
</ng-template>

<ng-template #emptyCustom>
  <div class="p-5">
    <ng-content select="arch-dataset-empty"></ng-content>
  </div>
</ng-template>