import { Pipe, PipeTransform } from '@angular/core';
import { ArchBowlEntry } from '../@services/competition.service';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'testScore',
  standalone: true,
})
export class TestScorePipe implements PipeTransform {
  transform(entry: ArchBowlEntry, questionIds: string[]) {
    if (!entry || !entry.state || !entry.state.scores) return null;
    const scores = entry.state.scores ?? {};
    return Object.keys(scores)
      .filter((key) => questionIds.includes(key))
      .map((key) => scores[key])
      .reduce((acc, score) => acc + score, 0);
  }
}
