<div class="text-center text-black-50 mx-auto" style="max-width:400px;">
  <fa-icon [icon]="icon" size="10x"></fa-icon>
  <h3 class="my-2" style="font-size:xx-large;">
    {{label}}
  </h3>
  <p style="font-size:large;">
    <em>
      <ng-content></ng-content>
    </em>
  </p>
</div>