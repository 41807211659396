import {
  Component,
  ContentChild,
  Directive,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentData } from '@angular/fire/firestore';
import { ArchLoadingComponent } from './arch-loading/arch-loading.component';
import { ArchErrorComponent } from './arch-error/arch-error.component';
import { DatasetEmptyComponent } from './dataset-empty/dataset-empty.component';

@Component({
  selector: 'arch-dataset',
  standalone: true,
  imports: [CommonModule, ArchLoadingComponent, ArchErrorComponent],
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
})
export class DatasetComponent {
  @Input() dataset!: Dataset<any>;
  @ContentChild(DatasetEmptyComponent)
  emptyDataset!: TemplateRef<DatasetEmptyComponent>;
}

export interface Dataset<T> {
  status: 'loading' | 'loaded' | 'error';
  data: T[];
  error?: any;
}
