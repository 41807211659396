import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FkBreadcrumbsService {
  breadcrumbs: Observable<FkBreadcrumb[]>[] = [];

  clear() {
    this.breadcrumbs = [];
  }

  activate(component: HasFkBreadcrumb, isRoot = false) {
    // reset breadcrumbs if this is the root router-outlet
    if (isRoot) {
      this.breadcrumbs = [];
    }

    if (component.breadcrumbs$) {
      this.breadcrumbs.push(component.breadcrumbs$);
    }
  }

  deactivate(component: HasFkBreadcrumb) {
    if (component.breadcrumbs$) {
      this.breadcrumbs.pop();
    }
  }
}

export interface HasFkBreadcrumb {
  breadcrumbs$: Observable<FkBreadcrumb[]>;
}

export interface FkBreadcrumb {
  label: string;
  routerLink: string | any[] | null | undefined;
  icon?: any;
}
